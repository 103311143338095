import getSiteTypeData from "../getSiteTypeData";
import mintedUrls from "./mintedUrls";
import { css } from "@emotion/react";
import { TextLinkInline, theme } from "@minted/minted-components";
import PropTypes from "prop-types";
import React, { memo } from "react";

const { media } = theme;

const SiteFooter = ({ siteType }) => {
  const { isWeddingSite } = getSiteTypeData({
    siteType,
  });
  const links = [
    {
      content: isWeddingSite ? "Wedding Website Help" : "Minted Websites Help",
      href: isWeddingSite ? mintedUrls.helpWedding : mintedUrls.help,
      key: "help",
    },
    {
      content: "Terms & Conditions",
      href: mintedUrls.terms,
      key: "termsAndConditions",
    },
    {
      content: "Privacy Policy",
      href: mintedUrls.privacy,
      key: "privacyPolicy",
    },
  ];

  return (
    <div
      css={css`
        ${theme.typeStyles.bodyExtraSmallStyles}
        align-items: center;
        display: flex;
        flex-direction: column;
        padding-bottom: ${theme.spacing.x16};
        padding-top: ${theme.spacing.x16};

        ${media.greaterThan("small")`
          ${theme.typeStyles.bodySmallStyles}
        `};
      `}
    >
      <nav
        css={css`
          color: ${theme.colors.gray700};
          display: flex;
          margin-bottom: ${theme.spacing.x4};
          text-align: center;

          ${media.greaterThan("small")`
            margin-bottom: ${theme.spacing.x8};
          `};
        `}
      >
        {links.map(({ content, href, key }) => (
          <div
            css={css`
              margin-left: ${theme.spacing.x2};
              margin-right: ${theme.spacing.x2};

              ${media.greaterThan("small")`
                    margin-left: ${theme.spacing.x6};
                    margin-right: ${theme.spacing.x6};
                  `};
            `}
            data-cy={`${key}FooterLink`}
            key={key}
          >
            <TextLinkInline
              hasUnderline={false}
              hasUnderlineOnHover
              href={href}
              target="_blank"
              text={content}
            />
          </div>
        ))}
      </nav>

      <div
        css={css`
          color: ${theme.colors.gray600};
        `}
        data-cy="copyrightText"
      >
        Copyright &copy; {new Date().getFullYear()} MINTED LLC, all rights
        reserved.
      </div>
    </div>
  );
};

SiteFooter.propTypes = {
  isWedding: PropTypes.bool,
};

export default memo(SiteFooter);
