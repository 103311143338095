import { SITE_TYPES } from "./core/constants";

export const getBaseUrl = (siteId) => `/site/${siteId}`;

const holidaySiteRoutes = [
  {
    children: [
      {
        name: "edit",
        path: "/edit",
      },
    ],
    name: "dashboard",
    path: "/dashboard",
  },
  {
    name: "settings",
    path: "/settings?:delete",
  },
];

const weddingSiteRoutes = [
  {
    name: "dashboard",
    path: "/dashboard",
  },
  {
    name: "designs",
    path: "/designs?:source",
  },
  {
    name: "settings",
    path: "/settings?:delete",
  },
  {
    children: [
      {
        name: "all",
        path: "/all",
      },
      {
        name: "rsvp",
        path: "/rsvp?:eventId",
      },
    ],
    name: "guests",
    path: "/guests",
  },

  // Pages nested routes
  {
    children: [
      {
        name: "home",
        path: "/home",
      },
      {
        name: "rsvp",
        path: "/rsvp",
      },
      {
        name: "events",
        path: "/events",
      },
      {
        name: "registry",
        path: "/registry",
      },
      {
        name: "photos",
        path: "/photos",
      },
      {
        name: "accommodations",
        path: "/accommodations",
      },
      {
        name: "wedding-party",
        path: "/wedding-party",
      },
      {
        name: "guest-book",
        path: "/guest-book",
      },
      {
        name: "custom",
        path: "/:id/:slug",
      },
      {
        name: "edit",
        path: "/edit",
      },
    ],
    name: "pages",
    path: "/pages",
  },
];

const onlineInvitationSiteRoutes = [
  // Default params are added to each route so that Qualtrics can
  // differentiate between an online invitation site and a wedding site
  {
    defaultParams: { siteType: "onlineInvitation" },
    name: "settings",
    path: "/settings?:delete?:siteType",
  },
  {
    defaultParams: { siteType: "onlineInvitation" },
    name: "guests",
    path: "/guests?:siteType",
  },
  // Pages nested routes
  {
    children: [
      {
        defaultParams: { siteType: "onlineInvitation" },
        name: "home",
        path: "/home?:siteType",
      },
      {
        defaultParams: { siteType: "onlineInvitation" },
        name: "events",
        path: "/events?:siteType",
      },
      {
        defaultParams: { siteType: "onlineInvitation" },
        name: "registry",
        path: "/registry?:siteType",
      },
      {
        defaultParams: { siteType: "onlineInvitation" },
        name: "custom",
        path: "/:id/:slug?:siteType",
      },
      {
        defaultParams: { siteType: "onlineInvitation" },
        name: "edit",
        path: "/edit?:siteType",
      },
      {
        defaultParams: { siteType: "onlineInvitation" },
        name: "guest-book",
        path: "/guest-book?:siteType",
      },
    ],
    defaultParams: { siteType: "onlineInvitation" },
    name: "pages",
    path: "/sections?:siteType",
  },
];

export const routesBySiteType = {
  [SITE_TYPES.WEDDING]: weddingSiteRoutes,
  [SITE_TYPES.ONLINE_INVITATION]: onlineInvitationSiteRoutes,
  [SITE_TYPES.HOLIDAY]: holidaySiteRoutes,
};
